.testimonial .testimonial__quote {
    display: block;
    max-width: 1000px;
    margin: 0 auto 0px auto;

    color: #a6262b;
    font-weight: bold;
    font-size: 20px;
    font-family: "ITC Avant Garde Gothic Std", sans-serif;
    line-height: 1.55;
    text-align: center;

    quotes: "\201C""\201D""\2018""\2019";
}

@media (min-width: 48em) {
    .testimonial .testimonial__quote {
        padding: 0 30px;

        font-size: 36px;
        text-align: center;
    }
}

@media (min-width: 62em) {
    .testimonial .testimonial__quote {
        padding: 0;
    }
}

/*.testimonial .testimonial__quote:before { content: open-quote; margin-left: -15px; } .testimonial .testimonial__quote:after { content: close-quote; } */

.testimonial .testimonial__author {
    display: table;
    margin: 0 auto;

    line-height: 0.5 !important;
}

.testimonial .testimonial__author__media,
.testimonial .testimonial__author__body {
    display: table-cell;

    vertical-align: middle;
}

.testimonial .testimonial__author__media {
    width: 90px;
    overflow: hidden;

    border-radius: 50%;
}

.testimonial .testimonial__author__media img {
    display: block;
    max-width: 100%;
}

.testimonial .testimonial__author__body {
    width: 210px;
    padding-left: 30px;
}

.testimonial .testimonial__author__body span {
    display: block;
}

.testimonial .testimonial__author__name,
.testimonial .testimonial__author__function {
    color: #777;
    font-weight: normal;
    font-size: 10px;
    font-family: "ITC Avant Garde Gothic Std", sans-serif;
    line-height: 0.5 !important;
}

.testimonial-wrapper .owl-nav {
    top: -73px;
    right: 30px;
    left: 30px;
}

@media (min-width: 991px) {
    .testimonial-wrapper .owl-nav {
        top: 50%;
        right: 30px;
        left: 30px;
    }
}
