.team {
    position: relative;

    margin: 0 0 30px;
    padding-bottom: 125.9259259259%;
    overflow: hidden;

    background-position: center;
    background-size: cover;
    border-radius: 5px;
}

.team figcaption {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;

    padding: 20px;
}

.team figcaption:after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    display: block;
    height: 100px;

    background: -moz-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.65) 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.65) 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.65) 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
    /* IE6-9 */
    content: "";
}

.team__name {
    display: block;

    color: #fff;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.6;
}

.team__function {
    display: block;

    color: #f2f2f2;
}

@media only screen and (max-width: 500px) {
    // .team {
    //
    //   figcaption{
    //     background-color: rgba(0, 0, 0, 0.5);
    //
    //     .team__name {
    //
    //     }
    //
    //     .team__function {
    //
    //
    //     }
    //   }
    // }
}

@media only screen and (max-width: 767px) {
    .team {
        figcaption {
            padding: 7px 0px 7px 7px;

            background-color: rgba(0, 0, 0, 0.5);
            border-top: 1px solid #333;

            .team__name {
                font-size: 14px;
                line-height: 1.2;
            }

            .team__function {
                padding-top: 2px;

                font-size: 11px;
            }
        }
    }
}
