.header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;

    padding: 10px 0;

    background-color: #fff;

    transition: 0.25s;
}

@media (min-width: 48em) {
    .header {
        height: 65px;
    }
}

.is-scrolling .header {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
}

.logo {
    display: block;
    max-width: 205px;
}

@media (min-width: 48em) {
    .logo {
        margin-top: 5px;
    }
}

.menu-trigger {
    position: absolute;
    top: 18px;
    right: 30px;
    z-index: 10000;

    display: block;
    padding: 0;

    background-color: transparent;
    border: 0;
}

@media (min-width: 48em) {
    .menu-trigger {
        display: none;
    }
}

.is-menu-open:after {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;

    display: block;

    background-color: rgba(51, 51, 51, 0.85);

    content: "";
}

.legal-nav {
    display: block;
    padding: 10px 5px;

    color: #686d70;
    font-size: 20px;
    letter-spacing: 0.5px;
}

.main-nav {
    display: none;
    margin-top: 15px;
}

.is-menu-open .main-nav {
    display: block;
}

@media (min-width: 48em) {
    .main-nav {
        display: block;
        margin-top: 0;
        padding: 5px 0;
    }
}

.main-nav a {
    position: relative;
}

.nav-inline a {
    color: #2f2f2f;
}

.main-nav li a:not(.btn) {
    display: block;
    padding: 10px 5px;

    color: #686d70;
    letter-spacing: 0.5px;

    border-bottom: 1px solid #ddd;
}

@media (min-width: 48em) {
    .main-nav li a:not(.btn) {
        padding: 0;

        border-bottom: 0;
    }
}

.nav-inline li {
    display: inline-block;

    vertical-align: middle;
}

.nav-inline li + li {
    padding: 0 0 0 40px;
}

.nav-inline--bordered li + li {
    border-left: 1px solid #fff;
}

.nav-inline--small li + li {
    padding: 0 0 0 5px;
}

.main-nav li:nth-last-child(-n + 2) {
    margin-top: auto;
    margin-bottom: 20px;
}

@media (min-width: 48em) {
    .main-nav li:nth-last-child(-n + 2) {
        display: inline-block;
        margin-bottom: auto;
    }
}

.main-nav a.is-active {
    /* font-weight: bold; */
    color: #a6262b !important;
}

.main-nav a:before {
    position: absolute;
    top: -22px;
    right: 0;
    left: 0;

    display: none;
    height: 2px;

    content: "";
    // background-color: #a6262b;
}

@media (min-width: 768px) {
    .main-nav a.is-active:before {
        display: block;
    }
}

.main-nav .nav-inline__item--btn + .nav-inline__item--btn {
    padding: 0 0 0 15px;
}

@media (min-width: 48em) {
    .nav-inline\@sm li {
        display: inline-block;

        vertical-align: middle;
    }
    .nav-inline\@sm li + li {
        padding: 0 0 0 15px;
    }
}

@media (min-width: 48em) and (min-width: 75em) {
    .nav-inline\@sm li + li {
        padding: 0 0 0 40px;
    }
}

@media (min-width: 48em) {
    .nav-inline\@sm--bordered li + li {
        border-left: 1px solid #fff;
    }
    .nav-inline\@sm--small li + li {
        padding: 0 0 0 5px;
    }
}
