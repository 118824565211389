.services {
    .services_block {
        padding: 60px 0;

        .question-box {
            margin-bottom: 0;
        }

        .question-box__title,
        .question-box__body {
            color: #ffffff;
        }

        .question-box__body {
            margin: 0;
        }
    }

    @media (min-width: 768px) {
        .services_block {
            .flex-row {
                align-items: flex-start;
            }

            .question-box {
                margin-right: 5px;
                margin-left: 5px;
            }
        }
    }

    @media (min-width: 992px) {
        .services_block {
            .question-box {
                margin-right: auto;
                margin-left: auto;
            }
        }
    }
}
