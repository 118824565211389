.vimeo-image,
.vimeo-image:after,
.vimeo-embed {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}
.vimeo {
    position: relative;

    padding-bottom: 56.25%;

    background: #e2e2e2;
}
.vimeo iframe {
    border: 0;
}
.vimeo-image {
    background-position: center center;
    background-size: 100% auto;
}
.vimeo-image:after {
    z-index: 1;

    display: block;

    background: rgba(0, 0, 0, 0.3);

    content: "";
}
.vimeo-play-button,
.vimeo-loading {
    top: 50%;
    left: 50%;

    transform: translateX(-50%) translateY(-50%);
}
.vimeo-play-button {
    position: absolute;
    z-index: 2;

    width: 70px;
    padding: 0;

    background: none;
    border: 0;
}
.vimeo-play-button:focus {
    outline: none;
}
.vimeo-play-button svg {
    -webkit-filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.8));
    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.8));

    fill: #fff;
}
.vimeo-loading {
    position: absolute;
    z-index: 4;

    width: 32px;
    height: 32px;
}
.vimeo-loading svg {
    transform-origin: 50% 50%;

    -webkit-animation: spinner 0.8s infinite linear;
    animation: spinner 0.8s infinite linear;

    fill: #000;
}
.vimeo-embed iframe {
    width: 100%;
    height: 100%;
}
@-moz-keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@-o-keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes spinner {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.video-background-transparent {
    background: none;
    border: 1px lightgray solid;
    .vimeo-image:after {
        background: none;
    }
}
