.form-group label {
    width: 100%;

    color: #686d70;

    font-weight: 500;

    font-size: 18px;
    line-height: 1.555;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group label input[type="email"],
.form-group input[type="password"],
.form-group label input[type="password"],
.form-group input[type="number"],
.form-group select,
.form-group textarea {
    display: block;

    width: 100%;
    height: 52px;
    padding: 15px 15px;

    color: #686d70;
    font-size: 18px;

    border: 1px solid #d3dde1;
    border-radius: 5px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.form-group textarea {
    height: auto;
    min-height: 150px;
}

.contact_newsletter_text {
    margin: 0 0 0 5px;

    color: #686d70;

    font-weight: 400;

    font-size: 16px;
    line-height: 1;
}