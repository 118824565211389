.modal {
    text-align: center;
}

@media (min-width: 48em) {
    .modal:before {
        display: inline-block;
        height: 100%;

        vertical-align: middle;

        content: " ";
    }
}

.modal-dialog {
    display: inline-block;
    margin: 70px 20px auto;

    text-align: left;
    vertical-align: middle;
}

#quickscan .modal-dialog {
    width: 100%;
    max-width: 890px;
}

.request-access {
    color: #a6262b;
    font-size: 12px;
}

.request-access a {
    color: #a6262b;
    text-decoration: underline;
}

.modal-dialog:before {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;

    width: 104px;
    height: 104px;
    margin-top: -40px;
    margin-left: -52px;

    //   background-image: url(./assets/images/spade.svg);
    background-color: #fff;
    background-image: url("./images/spade.svg");
    background-repeat: no-repeat;
    background-position: center 20px;
    border-radius: 50%;

    content: "";
}

.modal-content {
    min-height: 200px;
    overflow: hidden;

    border: 0;
    box-shadow: none;
}

.modal-content .title {
    position: relative;
    z-index: 2;

    margin: 40px 0 10px 0;
}

.modal-content .title + p {
    max-width: 570px;
    margin: 0 auto 40px auto;
}

.modal-body {
    padding: 15px 60px;
}

.modal-footer {
    padding: 40px;

    color: #686d70;
    font-size: 16px;
    line-height: 1.625em;
    text-align: center;

    background-color: #f1f4f5;

    border-top: 0;
}

.modal-footer a {
    color: #a6262b;
}

.modal-video {
    position: relative;

    max-width: 100%;
    height: 0;

    padding-bottom: 56.25%;
    overflow: hidden;
}

.modal-video iframe {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border: 0;
}

[data-dismiss] {
    position: absolute;
    top: 4px;
    right: 17px;

    font-size: 36px;
    line-height: 1;
}

.login {
    max-width: 370px;
    margin: 0 auto;
}

.login .link {
    font-size: 16px;
}

.login__actions {
    margin-top: 30px;
    margin-bottom: 15px;
}

.questionnaire {
    position: relative;

    margin: 0 -60px;
}

.questionnaire__step {
    position: absolute;
    top: 25px;
    left: 50%;
    z-index: 10;

    color: #686d70;
    font-weight: bold;
    font-family: "ITC Avant Garde Gothic Std", sans-serif;

    transform: translateX(-50%);
}

.questionnaire__item {
    text-align: center;
}

.questionnaire__item.is-loaded {
    display: block;
}

.questionnaire__body {
    margin-bottom: 0;
    padding: 60px 60px 45px 60px;

    background-color: #f1f4f5;
}

.questionnaire__body input {
    max-width: 200px;
    margin: 0 auto;
}

.questionnaire__question {
    display: block;
    margin: 0 0 20px;

    color: #686c70;
    font-weight: bold;
    font-size: 26px;
    font-family: "ITC Avant Garde Gothic Std", sans-serif;
    line-height: 1.15;
}

.questionnaire__next,
.questionnaire__prev {
    margin: 40px 0 25px;
}

.modal-sherpa {
    text-align: justify;

    background-image: url("./images/sherpa_background.svg");
    background-repeat: no-repeat;
    background-size: cover;
}
