.cookie-banner-wrapper {
    background-color: rgba(52, 64, 81, 0.88);

    .react-cookie-banner {
        max-width: 1420px;
        height: 100% !important;
        margin: 0 auto;

        font-weight: 600;
        font-size: 15px;

        background: url(/assets/images/cookie.png) 20px 50% no-repeat !important;
        background-size: 30px 30px !important;
    }

    .button-close {
        top: 43% !important;
        right: 20px !important;

        width: 66;
        height: 32px;
        margin-top: -18px;

        color: #fff !important;
        font-weight: 600;
        font-size: 14px;
        line-height: 32px;

        background: transparent !important;
        border: 1px solid #fff !important;
        border-radius: 5px;

        opacity: 1;
    }

    .cookie-message {
        display: block;
        width: 98%;
        margin-right: 244;
        padding: 13px 67px;

        color: #fff;
        line-height: 1.3 !important;
        text-align: left;
    }
}
