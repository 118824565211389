.ipad {
    max-height: 595px;
    margin-bottom: -100px;
    padding: 25px;

    overflow: hidden;

    background-color: #fff;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.media-img {
    padding-bottom: 80%;

    background-position: center center;
    background-size: cover;
}

@media (min-width: 48em) {
    .media-img {
        padding-bottom: 100%;
    }
}

.media-img--stretch-right {
    margin: 0 -15px;
}

@media (min-width: 48em) {
    .media-img--stretch-right {
        margin: 0;
        margin-right: -127px;
        padding-top: 120px;
    }
}

@media (min-width: 62em) {
    .media-img--stretch-right {
        margin-right: calc((-100vw + 955px) / 2);
    }
}

@media (min-width: 75em) {
    .media-img--stretch-right {
        margin-right: calc((-100vw + 1170px) / 2);
    }
}

.img-stretched {
    display: flex;
    flex-direction: column;
}

@media (min-width: 48em) {
    .img-stretched {
        flex-direction: row;
    }
}

.img-stretched__media {
    order: -1;
}

@media (min-width: 48em) {
    .img-stretched__media {
        order: 1;
    }
}

.img-stretched__media .media-img {
    height: 100%;
}

.flex-switch {
    display: flex;
    flex-direction: column;
}

.flex-switch-direction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.flex-switch-direction-reverse {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
}

.flex-switch__item {
    padding-top: 10px;
}

.flex-switch .flex-switch__item:last-of-type {
    order: -1;
}

@media (min-width: 48em) {
    .flex-switch {
        display: block;
    }

    .flex-switch-direction {
        flex-direction: row;
    }

    .flex-switch-direction-reverse {
        flex-direction: row;
    }
}

.flex-row {
    display: flex;
    align-items: center;
}

.flex-row .col-media {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    @media (min-width: 48rem) {
        max-width: initial;
    }
}

.small-row {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-center-space-around {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

@media (min-width: 767px) {
    .col-media-left {
        padding-right: 30px !important;
    }
}

@media (max-width: 767px) {
    .flex-center {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .ul-flex\@sm {
        display: flex;
        flex-direction: column;
    }

    .socialmedia {
        margin-top: 10px;
        margin-right: 19px;
    }
}

.row-separated {
    margin-top: 20px;
}

@media (min-width: 62em) {
    .flex-row .col-media {
        display: flex;
        align-items: center;
        justify-content: center;
        order: 0;
    }
}

.mw-270 {
    max-width: 270px;
}

.mw-370 {
    max-width: 370px;
}

.mw-470 {
    max-width: 470px;
}

.mw-570 {
    max-width: 570px;
}

.mw-670 {
    max-width: 670px;
}

.mw-770 {
    max-width: 770px;
}

.mw-870 {
    max-width: 870px;
}

.mw-970 {
    max-width: 970px;
}

@media (min-width: 48em) {
    .text-left\@sm {
        text-align: left;
    }
}

@media (min-width: 62em) {
    .text-left\@md {
        text-align: left;
    }
}

@media (min-width: 75em) {
    .text-left\@lg {
        text-align: left;
    }
}

@media (min-width: 48em) {
    .text-center\@sm {
        text-align: center;
    }
}

@media (min-width: 62em) {
    .text-center\@md {
        text-align: center;
    }
}

@media (min-width: 75em) {
    .text-center\@lg {
        text-align: center;
    }
}

@media (min-width: 48em) {
    .text-right\@sm {
        text-align: right;
    }
}

@media (min-width: 62em) {
    .text-right\@md {
        text-align: right;
    }
}

@media (min-width: 75em) {
    .text-right\@lg {
        text-align: right;
    }
}

@media (min-width: 48em) {
    .block-right\@sm {
        margin: 0 0 0 auto;
    }
}

@media (min-width: 62em) {
    .block-right\@md {
        margin: 0 0 0 auto;
    }
}

@media (min-width: 75em) {
    .block-right\@lg {
        margin: 0 0 0 auto;
    }
}

.divider {
    margin: 0 auto;

    border-bottom: 1px solid #d3dde1;
}

.block {
    display: block;
}

.list-unstyled {
    margin-bottom: 0;
}

.relative {
    position: relative;
}

.scrollable-horizontal {
    overflow-x: scroll;
    overflow-x: auto;
}

.accessibility {
    position: absolute;
    z-index: -1;

    opacity: 0;

    pointer-events: none;
}

.flex-break {
    flex-basis: 100%;
    height: 0;
}
