body {
    padding-top: 65px;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    overflow-x: hidden;
    overflow-y: scroll;

    font-family: "Arimo", sans-serif;
}

.outside-wrapper {
    max-width: 1440px;
    margin: 0 auto;
}

.container--970 {
    width: 100% !important;
    max-width: 1000px;
}

.container--1250 {
    width: 100% !important;
    max-width: 1250px;
}

.container--1400 {
    width: 100% !important;
    max-width: 1400px;
}

@media (max-width: 767px) {
    .row--flex-column-reverse {
        display: flex;
        flex-direction: column-reverse;
    }

    .row--flex-column-small-screen {
        display: flex;
        flex-direction: column;
    }

    .well-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .paragraph--small--small-screen {
        font-size: 12px;
        line-height: 1.555;
    }

    .well-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.row--flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Bootsrap improvements */
.form-group {
    margin-bottom: 10px;
}

.center-text {
    text-align: center;
}

@media screen and (min-width: 768px) {
    .flex-row .col-md-4 {
        @media screen {
            width: 33.3%;
        }
    }
}

.flex-row .col-md-5 {
    @media (min-width: 991px) {
        width: 41%;
    }
}

.pb-xs-3 {
    padding-top: 30px;
    padding-bottom: 30px;
}

.mb-xs-2 {
    margin-bottom: 20px;
}

.mb-xs-3 {
    margin-bottom: 30px;
}

.mb-xs-4 {
    margin-bottom: 40px;
}

.mb-xs-6 {
    margin-bottom: 60px;
}

.mt-xs-14 {
    margin-top: 80px;
}

.mt-xs-2 {
    margin-top: 20px;
}

@media (min-width: 48em) {
    .mt-sm-2 {
        margin-top: 20px;
    }

    .mt-sm-3 {
        margin-top: 30px;
    }
}

ul,
ol {
    color: #686d70;
    font-size: 20px;
}

@media (min-width: 1200px) {
    .container {
        width: 1200px;
    }
}

@media (max-height: 667px) {
    .mobile-height .mt-xs-14 {
        margin-top: 20px;
    }
    .mobile-height .title--xlg {
        margin-bottom: 10px;
    }
    .mobile-height .mt-xs-5 {
        margin-top: 30px;
    }
    .mobile-height .mb-xs-4 {
        margin-bottom: 20px;
    }
    .mobile-height .scroll-slider {
        margin: 10px 0 0;
    }
}

@media (min-height: 668px) and (max-height: 768px) {
    .mobile-height .mt-xs-14 {
        margin-top: 30px;
    }

    .mobile-height .scroll-slider {
        margin: 25px 0 0;
    }

    h2:first-of-type {
        margin-top: 25px;
    }
}

.table {
    color: #686d70;
    font-size: 16px;
}

.table.table-striped tbody tr:nth-child(odd) {
    background-color: #fff;
}

.table td {
    padding: 15px; /* !important; */
}

.list-table {
    width: 100%;
}

.list-table tr td {
    padding: 30px 0;
}

.list-table tr + tr td {
    border-top: 1px solid #dae2e5 !important;
}

.list-table tr td:last-child {
    width: 200px;

    text-align: right;

    border-top: 0;
}

.list-table .list-table__title {
    font-size: 28px;
}

.list-table .list-table__subtitle {
    color: #686d70;
    font-size: 18px;
}

.well {
    padding: 35px;
}

.well-title {
    float: left;
    margin: 12px 30px 0 0 !important;

    font-size: 26px;
}

.well-link {
    float: right;
}

.well-primary {
    background-color: #a6262b;
    border-color: #a6262b;
}

.well--default {
    background-color: #f1f4f5;
    border-color: #f1f4f5;
}

.well--default .well-title {
    color: #686c70;
}

.well--default .well-link .btn {
    color: #fff;

    background-color: #a4b9c4;
}

.well--block .well-title {
    float: none;
}

.text-page p {
    color: #686d70;
    font-size: 20px;
}

.well--block .well-title + p {
    margin: 15px 0;

    color: rgba(255, 255, 255, 0.5);
    font-weight: bold;
    font-size: 18px;
    font-family: "ITC Avant Garde Gothic Std", sans-serif;
}

.well--block .well-link {
    float: none;
}

.text-page h2,
.text-page h3 {
    font-weight: bold;
    font-size: 32px;
    font-family: "ITC Avant Garde Gothic Std", sans-serif;
}

.text-page h3 {
    color: #696e71;
    font-size: 22px;
}

.text-page p + h2,
.text-page p + h3 {
    margin-top: 60px;
}

.text-page ul {
    margin-bottom: 40px;
    padding: 0;

    color: #686d70;
    font-size: 18px;
    line-height: 1.555;
}

.form--calculate label {
    display: block;

    font-weight: normal;
}

.form--calculate-intro input {
    margin-bottom: 15px;
}

.form--calculate-intro .form-group + .form-group {
    margin-top: -25px;
}

@media (min-width: 768px) {
    .form--calculate label {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        width: 100%;
        min-height: 52px;

        text-align: right;
    }
}

select {
    background-color: #fff;
}

@media (min-width: 768px) {
    .cycle-item.body-right {
        padding: 0;
    }
    .cycle-item:not(.body-right) {
        padding: 0 30px 0 0;
    }
}

@media (min-width: 991px) {
    .cycle-item.body-right {
        padding: 0 0 0 30px;
    }
}

.subtitle {
    color: rgba(255, 255, 255, 0.6);
    font-weight: bold;
    font-size: 22px;
    font-family: "ITC Avant Garde Gothic Std", sans-serif;
}

.back-to {
    display: block;
    float: left;
    padding: 10px 30px;

    color: #686d70;
    font-size: 16px;
}

.header-compact-actions {
    float: right;
    padding: 0 30px;
}

tfoot {
    background-color: #fff;
}

.table-foot {
    color: #a6262b;
}

.scroll-slider {
    position: relative;

    margin: 0;
}

@media (min-width: 1200px) {
    .scroll-slider {
        margin: 92px 0 0;
    }
}

.scroll-slider:before,
.scroll-slider:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 50;

    display: block;
    width: 150px;

    background: -moz-linear-gradient(
        left,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
        left,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    content: "";
}

.scroll-slider__item:before {
    position: relative;
    right: 0;
    bottom: -170px;
    left: 0;

    display: block;
    height: 20px;

    background: -moz-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
        top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    content: "";
}

.scroll-slider:after {
    right: 0;
    left: auto;

    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.scroll-slider__item {
    position: relative;
    z-index: 1;

    margin: 0;
    padding-bottom: 170px;

    background-size: 95em;
    opacity: 0.3;

    transition: 0.75s cubic-bezier(0.41, 0.93, 0.12, 0.96);
}

.scroll-slider__item:nth-child(3):after {
    display: none;
}

.scroll-slider__item:nth-child(2),
.scroll-slider__item:nth-child(3) {
    margin-top: -160px;
}

@media (min-width: 1200px) {
    .scroll-slider__item {
        padding-bottom: 170px;
    }

    .scroll-slider__item:nth-child(2),
    .scroll-slider__item:nth-child(3) {
        margin-top: -160px;
    }
}

.scroll-slider__item:nth-child(2) {
    z-index: 5;

    opacity: 0.6;
}

.scroll-slider__item:nth-child(3) {
    z-index: 10;

    margin-bottom: 0;

    opacity: 1;
}

#contact .login {
    width: 100%;
    max-width: 800px;
    max-height: 650px;
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/*Foldable Benefit*/
.collapsible-arrow {
    float: right;

    text-align: right;
}

.collapsible-arrow img {
    width: 10px;

    vertical-align: middle;
}

.arrow-opened .collapsible-arrow img {
    transform: rotate(90deg);
}

.Collapsible .paragraph {
    font-size: 16px;
}
.teaser-content {
    display: table-cell;
    height: 350px;
    padding: 15px;

    text-align: center;
    vertical-align: middle;
}
.teaser-container {
    width: 100%;
    height: 350px;

    text-align: center;

    background:
    /* top, transparent red, faked with gradient */ linear-gradient(
            rgba(166, 38, 43, 0.85),
            rgba(166, 38, 43, 0.85)
        ),
        /* bottom, image */ url(/assets/images/teaser-bg.jpg);
}
.white-text {
    color: #e7e7e6 !important;

    background-color: #00000010 !important;
    border-radius: 10px;
}

.noMargin {
    margin: 0 !important;
}

.lilPadding {
    padding: 40px 0 !important;
}

.lilPaddingTop {
    padding-top: 40px 0 0 0 !important;
}

.linkHeader a{
    color: #a6262b !important;
    font-weight: 600;
    font-size: 20px;
}

.attribution {
    color: #ffff90;
    font-size: 12px;
    //italic
    font-style: italic;
}

.logo-center {
    display: block;
    margin: 0 auto;
    padding-bottom: 20px;
}

.whiteText {
    color: #f1f4f5;
}

.hideOnMobile {
    display: block;
}

@media (max-width: 767px) {
    .hideOnMobile {
        display: none;
    }
}