.product_testimonials {
    padding: 40px 0;

    .owl-carousel .owl-stage {
        display: flex;
        align-items: center;
    }

    .owl-nav > div {
        width: 20px !important;

        color: transparent !important;

        background: url("/assets/images/arrow-left-white.svg") center no-repeat !important;
    }

    .title {
        margin: 0 0 5px;
    }

    .quotes-container {
        position: absolute;
        top: 0;
        left: 0;

        display: none;

        width: 100%;
        height: 100%;
    }

    .quotes-wrapper {
        height: 100%;
    }

    .quotes-open,
    .quotes-close {
        position: absolute;

        z-index: -1;

        width: 200px;
        height: 225px;
    }

    .quotes-open {
        top: -55px;
        left: 0;

        background: url("/assets/images/quote-marks-open.svg") center no-repeat;
    }

    .quotes-close {
        right: 0;
        bottom: -55px;

        background: url("/assets/images/quote-marks-close.svg") center no-repeat;
    }

    .testimonial__item {
        position: relative;

        max-width: 1020px;
        margin: 0 auto;
        padding: 0;
    }

    .testimonial__wrapper {
        display: flex;
        flex-direction: column;
        max-width: 820px;
        margin: 0 auto;
        padding: 0 20px;

        line-height: 1.2;
    }

    .testimonial__quote {
        padding: 0;

        color: #ffffff;
        font-weight: 300;
        font-size: 32px;
        font-family: "Helvetica Neue Light", sans-serif;
        line-height: 1.1;

        text-align: center;
    }

    .testimonial__author img {
        width: 85px;
        height: 85px;
        margin-bottom: 20px;

        background-color: #ffffff;
        border-radius: 50%;
    }

    .testimonial__author__name {
        margin: 20px 0 0;

        color: #fff;
        font-weight: 700;
        font-size: 20px;
        font-family: "Helvetica Neue Ultra Light", sans-serif;
        line-height: 1.1 !important;
        text-align: center;
    }

    @media (min-width: 768px) {
        padding: 60px 0 50px;

        .quotes-container {
            display: block;
        }

        .testimonial__wrapper {
            flex-direction: row;
        }

        .testimonial__item {
            padding: 40px 0 10px;
        }

        .testimonial__author img {
            margin-right: 30px;
            margin-bottom: 0;
        }

        .testimonial__quote {
            text-align: left;
        }

        .testimonial__author__name {
            text-align: right;
        }
    }

    @media (min-width: 992px) {
        .testimonial__wrapper {
            padding: 0;
        }
    }
}
