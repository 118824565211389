.qr-codes-section {
    display: none;
}

@media (min-width: 768px) {
    .qr-codes-section {
        display: block;
    }

    .install-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        min-height: 175px;
    }
}

@media (min-width: 992px) {
    .qr-codes-section {
        .qr-code {
            min-height: 120px;
        }
    }
}

@media (min-width: 1200px) {
    .qr-codes-section {
        .flex-row {
            margin-top: 5px;
        }

        .qr-code {
            min-height: 150px;
        }
    }
}

.img-responsive{
    border-radius: 10px !important;
}
