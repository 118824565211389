.box {
    padding: 20px 0;
}

.box--padded {
    padding: 30px 10px;
}

.box--primary {
    color: #fff;

    background-color: #a6262b;
}

.box--dark {
    color: #fff;

    background-color: #343c40;
}

.box--light {
    background-color: #f5f7f8;
}

.box--up-your-game {
    padding-bottom: 90px;

    background-image: url("./images/up-your-game.svg");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 1015px;
}

.box--reelcast {
    // padding-bottom: 90px;
    background-image: url("./images/reelCast.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1015px;
}

.box--reelcastEpisode {
    // padding-bottom: 90px;
    background-image: url("./images/reelMap.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1015px;
}

.box--reelcastdescription {
    background-color: #f5f7f8;
    border:rgba(52, 60, 64, 0.65) 1px solid;
    box-shadow: 0 0 10px rgba(52, 60, 64, 0.65);
}

.box--download-the-app {
    padding-top: 35px;
    padding-bottom: 90px;

    background-image: url("./images/up-your-game.svg");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 1015px;
}

.box--start {
    padding-top: 0;
}

.box--end {
    padding-bottom: 0;
}

.box--dark--gradient-mobile {
    position: relative;
}

.box--dark--gradient-mobile:before,
.box--dark--gradient-mobile:after {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;

    display: block;
    width: 65px;

    background: -moz-linear-gradient(
        left,
        rgba(52, 60, 64, 0.65) 0%,
        transparent 100%
    );
    /* FF3.6-15 */
    background: -webkit-linear-gradient(
        left,
        rgba(52, 60, 64, 0.65) 0%,
        transparent 100%
    );
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to right,
        rgba(52, 60, 64, 0.65) 0%,
        transparent 100%
    );
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6343c40', endColorstr='#00000000', GradientType=1);
    /* IE6-9 */
    content: "";
    pointer-events: none;
}

.box--dark--gradient-mobile:before {
    left: 0;
}

.box--dark--gradient-mobile:after {
    right: 0;

    transform: rotate(180deg);
}

.chapter + .chapter {
    margin-top: 60px;
    padding-top: 60px;

    border-top: 1px solid #d3dde2;
}

.question-box {
    max-width: 300px;
    min-height: 20em;
    margin: 0 auto 45px;
}

.question-box .question-box__media {
    display: flex;
    height: 8em;
}

.question-box .question-box__title,
.question-box__title span {
    display: block;
    margin: 20px 0 5px 0;

    color: #a6262b;
    font-weight: bold;
    font-size: 24px;
    font-family: "ITC Avant Garde Gothic Std", sans-serif;
    line-height: 1.36;
}

.question-box .question-box__body {
    min-height: 10em;

    color: #777777;
    font-size: 15px;
    line-height: 1.46;
}

.question-box__image img {
    max-width: 100%;
    height: 8em;
    margin: 0 auto;
}

.box-background--slot_symbols {
    padding-right: 20px;
    padding-left: 20px;

    background-image: url("./images/slot_symbols_background.svg");
}

.plan-description-legend {
    display: flex;
    justify-content: space-evenly;
    width: 40%;
    margin: auto;
    padding-top: 40px;

    color: #7a7a7a;
    font-size: 12px;
}

@media (max-height: 667px) {
    .box {
        padding: 20px 0;
    }
}

@media (min-width: 48em) {
    .box {
        padding: 60px 0;
    }

    .question-box {
        min-height: 20em;
        margin: 0 auto 30px;
    }

    .box--up-your-game {
        padding-bottom: 120px;

        background-size: 100%;
    }

    .box--reelcast {
        padding-bottom: 12px;

        background-size: 100%;
    }

    .box--download-the-app {
        background-size: 100%;
    }

    .box--dark--gradient-mobile:before,
    .box--dark--gradient-mobile:after {
        display: none;
    }

    .box-background--slot_symbols {
        padding-right: 20px;
        padding-left: 20px;
    }
}
