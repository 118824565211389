// dear future dev,
// section .box box .box--light box--light
// if something broke, and is getting “display:inline-block”
// this is why
section.box.box--light {
    display: inline-block;
    width: 100%;
}

.checkbox-material .sv-hidden {
    display: none;
}

.sv_container > div:nth-of-type(1) {
    margin: 0 0 0 1em;
    h3 {
        font-weight: bolder;
    }
}

.sv_container {
    max-width: 1000px;
    margin: 0 auto;
    .panel-body {
        .sv_qstn {
            margin-top: 15px;
            div {
                h5 {
                    color: #a6262b;
                }
            }
            .table {
                tr {
                    td:not(:first-child),
                    th:not(:first-child) {
                        text-align: center;
                        .sv_q_m_label {
                            text-indent: -9px;
                        }
                    }
                }
            }
        }
    }
}
//---Temporarily fix yellow rows and red rows---
#sq_100 div div div ul li:nth-child(1) span {
    color: #8a6d3b;

    background-color: #fcf8e3;
    border: 1px solid #8a6d3b;
}

#sq_100 div div div ul li:nth-child(2) span {
    color: #a94442;

    background-color: #f2dede;
    border: 1px solid #a94442;
}
//---------------------------------------------
.sv_row input[type="radio"].sv-visuallyhidden {
    margin: 4px 5px 0;
}

.svp_p_container {
    font-family: “ITC Avant Garde Gothic Std”, sans-serif !important;
}

.panel-footer {
    padding: 10px 0px 10px 0px;

    background-color: none;
    background-color: #f5f7f8;
}

.screen-height {
    min-height: calc(100vh - 11em);
}

.cell-content--centered {
    margin-left: 43%;
}

.cabinet-survey {
    legend {
        display: none;
        margin-bottom: 4px;
    }
    /* stylelint-disable*/
    th,
    td {
        padding: 5px;

        text-align: center;
        vertical-align: middle !important;
    }

    td {
        font-size: 12px;
    }
    /* stylelint-enable*/

    .form-control {
        max-height: 200px;

        font-size: 12px;
    }

    .survey-header--provided {
        border-right: 6px solid darkgray !important;
    }

    .survey-header--smaller {
        width: 8%;
    }

    .survey-header--small {
        width: 10%;
    }

    .survey-header--medium {
        width: 18%;
    }

    .survey-header--large {
        width: 28%;
    }

    .chosen-form {
        border: 1px solid rgb(185, 29, 26);
    }
    [data-chosen=""] {
        background-color: rgba(248, 215, 218, 0.4);
    }
    [data-chosen]:not([data-chosen=""]) {
        background-color: rgba(255, 243, 205, 0.4);
    }

    datalist {
        display: none;
    }

    input::-webkit-calendar-picker-indicator {
        display: none;
    }

    .panel-footer.card-footer {
        border: none;
    }
}

.CostModel-survey {
    /* stylelint-disable*/
    td {
        display: table-cell !important;
    }

    th {
        width: 12.5%;
    }
    /* stylelint-enable*/

    td :first-child {
        width: 50%;
    }
}
