.grid {
    margin-bottom: -30px;
}

.grid li {
    margin-bottom: 30px;
}

.grid__item {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.grid__item:before {
    float: left;
    width: 1px;
    height: 0;
    margin-left: -1px;

    content: "";
}

.grid__item:after {
    display: table;
    clear: both;

    content: "";
}

.grid__item--squared-large:before {
    padding-bottom: 100%;
}

@media (min-width: 48em) {
    .grid__item--squared-large:before {
        padding-bottom: 101.8%;
    }
}

@media (min-width: 62em) {
    .grid__item--squared-large:before {
        padding-bottom: 100%;
    }
}

.grid__item--squared:before {
    padding-bottom: 100%;
}

.grid__item--rectangle:before {
    padding-bottom: 47.36842105%;
}
