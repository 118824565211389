.logos {
    margin: 15px;
}

.logo-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 75px;
    padding: 0 30px;
}

.logo-block img {
    width: auto !important;
    max-width: 100%;
    max-height: 60px;
}
