.btn {
    letter-spacing: 1px;
    text-transform: uppercase;
}

.btn:hover {
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.btn-vacancy {
    margin-bottom: 20px;
}

@media (min-width: 48em) {
    .btn + .btn {
        margin-left: 15px;
    }
}

.btn-default {
    color: #686d70;
}

.btn-default:hover {
    color: #fff;

    background-color: #333c40;
}

.btn-primary {
    color: #fff;

    background-color: #a6262b;
    border-color: #a6262b;
}

.btn-orange {
    color: #fff;

    background-color: #ed7d31;
    border-color: #ed7d31;
}

.btn-transparent {
    color: #a6262b;

    background-color: transparent;
    border-color: #a6262b;
}

.btn-primary:hover {
    background-color: #c3251c;
    border-color: #c3251c;
}

.btn-white {
    background-color: #fff;
    border-color: #fff;
}

.box--primary .btn-white {
    color: #a6262b;
}

.btn-lg,
.btn-group-lg > .btn {
    min-width: 140px;
    padding: 12px 14px;

    font-size: 14px;
}

.btn--color-red {
    color: #a6262b;
}

.btn-icon {
    padding-left: 34px;

    color: #a6262b;
}

.btn-icon i {
    margin: 0 5px 0 -20px;
}

.btn-download {
    display: table;
    margin: 0 auto;

    color: #fff;
    font-size: 14px;
    font-family: "ITC Avant Garde Gothic Std", sans-serif;
    text-transform: uppercase;
}

.btn-download:hover {
    color: #fff;
}

@media (max-width: 767px) {
    .btn-block-mobile {
        display: block;
        width: 100%;
        margin: 0 auto;
    }
}

.btn-block-mobile + .btn {
    margin-top: 15px;
}

@media (min-width: 48em) {
    .btn-block-mobile + .btn {
        margin-top: 0;
    }
}
