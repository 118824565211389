.questions {
    width: auto;
    max-width: 1200px;
    margin: 0 auto;

    color: #fff;
}

@media (min-width: 48em) {
    .questions {
        width: 100%;
    }
}
