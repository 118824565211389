.reelhot {
    .owl-stage {
        margin: 0 auto;
    }

    .owl-nav {
        position: relative;
    }
}

.reelhot-availability {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 35px;

    font-size: 16px;

    span {
        margin-right: 20px;
    }
}

.reelhot-indexes {
    .owl-nav {
        .owl-prev,
        .owl-next {
            margin-top: -128px;
        }
    }

    .reelhot-indexes-title {
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: 30px;

        h2 {
            margin: 5px 0 0;
        }

        .reelhot-indexes-select {
            z-index: 3;

            width: 210px;

            margin-top: 15px;
        }
    }
}

.reelhot-index {
    display: flex;
    flex-direction: column;
    align-items: center;

    cursor: pointer;

    img {
        width: auto !important;
        height: 155px;

        cursor: pointer !important;

        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 200ms;
        transition-property: box-shadow;
    }

    p {
        margin: 15px 0 0;

        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
    }

    &:hover {
        img {
            box-shadow: 0 0 12px #b2b2b2;
        }
    }
}

.spotlight-hidden-gems {
    margin: 50px 0 0;

    .spotlight-hidden-gems-description {
        margin-bottom: 50px;

        p,
        h2 {
            margin: 0 0 20px;
        }

        img {
            width: 100%;
            height: auto;

            cursor: pointer;

            &:hover {
                box-shadow: 0 0 12px #b2b2b2;
            }
        }
    }

    .reelhot-select-container {
        margin: 0 auto 15px;

        h4 {
            margin-bottom: 20px;

            font-weight: 600;
            font-size: 18px;
            line-height: 22px;

            text-align: center;
        }

        .reelhot-select-wrapper {
            z-index: 3;

            flex-direction: row;
            justify-content: center;

            .reelhot-suppliers-select,
            .reelhot-cost-model-select {
                width: 150px;
            }

            .reelhot-cost-model-select {
                margin-left: 20px;
            }
        }
    }

    .spotlight-hidden-gems-carousel {
        .owl-nav {
            .owl-prev,
            .owl-next {
                margin-top: -104px;
            }
        }

        figure {
            display: flex;
            justify-content: center;

            width: auto;
            padding: 15px;
        }

        /* stylelint-disable no-descending-specificity */
        img {
            width: auto;
            height: 130px;

            cursor: pointer !important;

            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 200ms;
            transition-property: box-shadow;

            &:hover {
                box-shadow: 0 0 12px #b2b2b2;
            }
        }
        /* stylelint-enable no-descending-specificity */

        .no-data {
            display: flex;
            align-items: center;
            justify-content: center;

            height: 107px;

            text-align: center;

            > div {
                width: 100%;
            }
        }
    }
}

.reelhot-details {
    /* stylelint-disable no-descending-specificity */
    img {
        width: 100%;
    }
    /* stylelint-enable no-descending-specificity */

    .button-wrapper {
        padding: 20px;

        text-align: center;
    }
}

@media (min-width: 576px) {
    .reelhot-availability {
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }
}

@media (min-width: 768px) {
    .reelhot {
        .owl-stage-outer {
            position: relative;

            &:after,
            &:before {
                position: absolute;
                bottom: 0;
                z-index: 2;

                width: 100px;
                height: 260px;

                background-color: #f5f7f8;

                content: "";
            }

            &:before {
                left: -1px;
            }

            &:after {
                right: -2px;
            }
        }
    }

    .reelhot-availability {
        justify-content: flex-start;

        margin: 35px 0 0;

        span {
            width: 120px;

            margin-right: 10px;
        }
    }

    .reelhot-indexes {
        .reelhot-indexes-title {
            position: relative;

            margin: 0 0 50px;

            .reelhot-indexes-select {
                position: absolute;
                top: -4px;
                right: 0;

                width: 90px;

                margin-top: 0;
            }
        }

        .owl-nav {
            .owl-prev,
            .owl-next {
                margin-top: -162px;
            }
        }

        h2 {
            margin: 0 0 60px;
        }
    }

    .reelhot-index {
        img {
            height: 180px;
        }

        p {
            margin-top: 30px;

            font-size: 24px;
            line-height: 28px;
        }
    }

    .spotlight-hidden-gems {
        margin: 80px 0 0;

        .spotlight-hidden-gems-description {
            margin-bottom: 80px;

            p {
                margin-bottom: 0;
            }

            h2 {
                margin-bottom: 45px;
            }

            .description-image {
                order: 1;
            }

            .description-text {
                order: 2;
            }
        }

        .reelhot-select-container {
            margin: 0 auto 50px;

            h4 {
                margin-bottom: 0;

                font-size: 24px;
                line-height: 28px;

                text-align: left;
            }

            .reelhot-select-wrapper {
                justify-content: flex-end;
            }
        }

        .spotlight-hidden-gems-carousel {
            .owl-nav {
                .owl-prev,
                .owl-next {
                    margin-top: -100px;
                }
            }

            figure {
                justify-content: unset;
            }

            img {
                height: 140px;
            }

            .no-data {
                height: 167px;
            }

            .owl-stage-outer {
                &:after,
                &:before {
                    width: 107px;
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .reelhot-availability {
        align-items: center;
        justify-content: flex-end;

        span {
            width: auto;

            margin-right: 20px;
        }
    }

    .spotlight-hidden-gems .spotlight-hidden-gems-carousel {
        .owl-nav {
            .owl-prev,
            .owl-next {
                margin-top: -130px;
            }
        }

        figure {
            justify-content: center;
        }

        img {
            height: 190px;
        }

        .no-data {
            height: 227px;
        }
    }
}

@media (min-width: 1200px) {
    .reelhot-indexes {
        .owl-nav {
            .owl-prev,
            .owl-next {
                margin-top: -172px;
            }
        }
    }

    .reelhot-index {
        img {
            height: 190px;
        }
    }
}
