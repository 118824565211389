h1,
h2,
h3,
h4,
h5,
h6 {
    display: block;

    color: #a6262b;
}

h2 {
    font-size: 32px;
}

.box--primary h1,
.box--primary h2,
.box--primary h3,
.box--primary h4,
.box--primary h5,
.box--primary h6 {
    color: #fff;
}

h1:first-of-type,
h2:first-of-type,
h3:first-of-type,
h4:first-of-type,
h5:first-of-type,
h6:first-of-type {
    margin-top: 0;
}

@media (min-width: 48rem) {
    h2 {
        font-size: 42px;
    }
}

.title {
    display: block;

    font-weight: bold;
    font-family: "ITC Avant Garde Gothic Std", sans-serif;
}

.title--explanation {
    color: #b2b6b7;
    font-weight: bold;
    font-size: 18px;
}

.title--primary {
    color: #a6262b !important;
}

.title--white {
    color: #fff;
}

.title--banner {
    margin-bottom: 10px;

    color: #414141;
    font-weight: bold;
    font-size: 32px;
    font-family: "ITC Avant Garde Gothic Std", sans-serif;
}

@media (max-width: 767px) {
    .title--banner {
        font-size: 25px !important;
    }
    .paragraph--banner {
        font-size: 12px !important;
    }
    .button--store {
        display: none;
    }
}

.paragraph--emphasized {
    color: #a6262b;
    font-weight: bold;
    font-size: 24px;
    font-family: "ITC Avant Garde Gothic Std", sans-serif;
    line-height: 1.5;
}

.paragraph--banner {
    color: #686d70;
    font-size: 18px;
    font-family: "ITC Avant Garde Gothic Std", sans-serif;

    .paragraph--emphasized {
        font-size: 19px;
    }
}

.title--xlg {
    margin-bottom: 10px;

    font-size: 38px;
}

@media (min-width: 48em) {
    .title--xlg {
        font-size: 64px;
    }
}

@media (min-width: 1200px) {
    .title--xlg {
        font-size: 72px;
    }
}

.title--lg {
    margin-bottom: 15px;

    font-size: 32px;
}

@media (min-width: 48em) {
    .title--lg {
        font-size: 52px;
    }
}

.title--md {
    font-size: 36px;
}

.title--sm {
    font-size: 20px;
}

.paragraph {
    color: #686d70;
    font-size: 17px;
    line-height: 1.4;
}

@media (min-width: 48em) {
    .paragraph {
        font-size: 20px;
    }
}

.box--primary .paragraph {
    color: #fff;
}

.paragraph--small-lighter {
    margin-top: 30px;
    margin-bottom: 0;

    color: #5c5c5c;
    font-size: 14px;
}

.paragraph--smallest-lighter {
    margin-top: 30px;
    margin-bottom: 0;
    margin-left: 13px;

    color: #5c5c5c;
    font-size: 10px;
}

.paragraph--extra-smallest {
    font-size: 14px;
    line-height: 1;
}

.paragraph--smallest {
    font-size: 16px;
    line-height: 1.625;
}

.paragraph--small {
    font-size: 18px;
    line-height: 1.555;
}

.text--emphasized {
    font-weight: bold;
    line-height: 1.5;
}

.text--red {
    color: #a6262b;
}

.text--light-gray {
    color: lightgray;
}

.paragraph--white {
    color: #ffffff;
}

.paragraph--white--emphasized {
    display: block;

    font-weight: bold;
    font-family: "ITC Avant Garde Gothic Std", sans-serif;
}

.link--no-decoration:hover,
.link--no-decoration:focus {
    text-decoration: none;
}

.link {
    font-size: 18px;
    text-decoration: underline;
}

.link:hover {
    color: inherit;
}

.link--primary {
    color: #a6262b;
}

.link--emphasized {
    font-weight: 500;
    font-size: 14px;
    font-family: "ITC Avant Garde Gothic Std", sans-serif;
    text-transform: uppercase;
}

.fs-10 {
    font-size: 10px;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 18px;
}

.fs-19 {
    font-size: 19px;
}

.fs-20 {
    font-size: 20px;
}

.fs-21 {
    font-size: 21px;
}

.fs-22 {
    font-size: 22px;
}

.fs-23 {
    font-size: 23px;
}

.fs-24 {
    font-size: 24px;
}

.fs-25 {
    font-size: 25px;
}

.fs-26 {
    font-size: 26px;
}

.fs-27 {
    font-size: 27px;
}

.fs-28 {
    font-size: 28px;
}

.fs-29 {
    font-size: 29px;
}

.fs-30 {
    font-size: 30px;
}

.fs-31 {
    font-size: 31px;
}

.fs-32 {
    font-size: 32px;
}

.fs-33 {
    font-size: 33px;
}

.fs-34 {
    font-size: 34px;
}

.fs-35 {
    font-size: 35px;
}

.fs-36 {
    font-size: 36px;
}

.fs-37 {
    font-size: 37px;
}

.fs-38 {
    font-size: 38px;
}

.fs-39 {
    font-size: 39px;
}

.fs-40 {
    font-size: 40px;
}

.fs-41 {
    font-size: 41px;
}

.fs-42 {
    font-size: 42px;
}

.fs-43 {
    font-size: 43px;
}

.fs-44 {
    font-size: 44px;
}

.fs-45 {
    font-size: 45px;
}

.fs-46 {
    font-size: 46px;
}

.fs-47 {
    font-size: 47px;
}

.fs-48 {
    font-size: 48px;
}

.fs-49 {
    font-size: 49px;
}

.fs-50 {
    font-size: 50px;
}

.fs-51 {
    font-size: 51px;
}

.fs-52 {
    font-size: 52px;
}

.fs-53 {
    font-size: 53px;
}

.fs-54 {
    font-size: 54px;
}

.fs-55 {
    font-size: 55px;
}

.fs-56 {
    font-size: 56px;
}

.fs-57 {
    font-size: 57px;
}

.fs-58 {
    font-size: 58px;
}

.fs-59 {
    font-size: 59px;
}

.fs-60 {
    font-size: 60px;
}

.fs-61 {
    font-size: 61px;
}

.fs-62 {
    font-size: 62px;
}

.fs-63 {
    font-size: 63px;
}

.fs-64 {
    font-size: 64px;
}

.fs-65 {
    font-size: 65px;
}

.fs-66 {
    font-size: 66px;
}

.fs-67 {
    font-size: 67px;
}

.fs-68 {
    font-size: 68px;
}

.fs-69 {
    font-size: 69px;
}

.fs-70 {
    font-size: 70px;
}

.fs-71 {
    font-size: 71px;
}

.fs-72 {
    font-size: 72px;
}

.fs-73 {
    font-size: 73px;
}

.fs-74 {
    font-size: 74px;
}

.fs-75 {
    font-size: 75px;
}

.fs-76 {
    font-size: 76px;
}

.fs-77 {
    font-size: 77px;
}

.fs-78 {
    font-size: 78px;
}

h2:first-of-type {
    margin-top: 25px;
}
