.compatibility_container {
    padding: 25px 0;

    .owl-carousel .owl-stage {
        display: flex;
        align-items: center;
    }

    @media (min-width: 992px) {
        padding: 45px 0;
    }
}

.compatibility_wrapper {
    @media (min-width: 992px) {
        display: flex;
        align-items: center;
    }
}

.compatibility_title_wrapper {
    @media (min-width: 992px) {
        display: flex;
        justify-content: flex-start;
    }
}

.compatibility_title {
    margin: 0 0 10px;

    font-weight: 100;
    font-size: 24px;
    font-family: “ITC Avant Garde Gothic Std”, sans-serif;
    line-height: 1.2;
    text-align: center;

    opacity: 0.5;

    @media (min-width: 992px) {
        max-width: 250px;
        margin: 0;
        padding-left: 50px;

        text-align: left;
    }

    @media (min-width: 1200px) {
        padding-left: 70px;
    }
}

.compatibility_slide_item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    width: 100%;

    img {
        width: auto !important;
        margin: 5px 0;

        transform: scale(0.7);
        opacity: 0.5;
    }

    img.EDW {
        transform: scale(0.55);
    }

    @media (min-width: 768px) {
        img {
            transform: scale(0.9);
        }

        img.EDW {
            transform: scale(0.75);
        }
    }

    @media (min-width: 992px) {
        justify-content: normal;

        img {
            margin: 10px 0;
        }
    }

    @media (min-width: 1200px) {
        justify-content: space-evenly;

        img.EDW {
            margin: 5px 22px;
        }

        img#logo_vertica {
            margin: 5px 53px !important;
        }
    }

    // ipad pro portrait
    @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
        justify-content: normal;

        img.EDW {
            margin: 5px 0;
        }

        img#logo_vertica {
            margin: 5px 0 !important;
        }
    }
}
