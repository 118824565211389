.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9 {
    padding-right: 0px;
    padding-left: 0px;
}

.performance_profile_card table {
    margin: 0px;

    font-size: 11px;
}

.white-frame {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    background: #fff;
    box-shadow: 0 2px 2px #d2d2d2;
}

@media (max-width: 767px) {
    .white-frame {
        margin-top: 0;
        margin-bottom: 25px;
    }
}

.gray-border {
    padding: 0 2px 20px 2px;

    border: 3px solid #eceef0;
}

.small-logo {
    height: 20px;
    margin-top: 15px;
    margin-left: 15px;

    opacity: 0.5;
}

.card {
    margin-bottom: 30px;

    background-color: #fff;
    box-shadow: 0 0 12px #d2d2d2;

    h2 {
        font-size: 18px;
    }
    h3 {
        font-size: 15px;
    }
}
.card-clickable {
    cursor: pointer;
}
.overlay {
    position: absolute;
    top: 150px;
    z-index: 999;

    width: 90%;

    text-align: center;
}
.overlay > span[class*="btn"] {
    @media (min-width: 992px) {
        /* md */
        font-size: 10px;
    }
    @media (min-width: 1200px) {
        /* lg */
        font-size: 14px;
    }
}

.card-header {
    padding: 26px 30px;
    h2 {
        margin: 0px;
        padding: 0px;
    }
}
.card-body {
    padding: 0px 30px 26px 30px;
}
.show-grid {
    padding-top: 30px;
}
.card-locked-icon {
    position: absolute;
    top: 0;
    right: 0;

    padding-top: 23px;
    padding-right: 25px;

    color: black;
}
.card-locked {
    opacity: 0.5;
}
.card-active:after {
    position: absolute;
    bottom: 0px;
    left: 45%;

    width: 0;
    height: 0;

    background: transparent;
    border-right: 25px solid transparent;
    border-bottom: 25px solid #fff;
    border-left: 25px solid transparent;

    content: " ";
}

.CardsTabStrip.row div[class*="col-"] {
    @media (max-width: 768px) {
        /* xs */
    }
    @media (min-width: 768px) {
        /* sm */
        padding-right: 15px;
        padding-left: 15px;
    }
    @media (min-width: 992px) {
        /* md */
    }
    @media (min-width: 1200px) {
        /* lg */
    }
}

.card-plan-description {
    justify-content: space-between;
    max-width: 455px;
    min-height: 350px;
    padding: 20px;
}

.CardsTabStrip.row > div[class*="col-"]:first-child {
    padding-left: 0px;
}
.CardsTabStrip.row > div[class*="col-"]:last-child {
    padding-right: 0px;
}
.CardsTabStrip.row > div[class*="col-"] > .card {
    @media (max-width: 768px) {
        /* xs */
        .card-body {
            display: none;
        }
        h2 {
            font-size: 18px;
        }
    }
    @media (min-width: 768px) {
        /* sm */
        .card-body {
            display: none;
        }
        h2 {
            font-size: 14px;
        }
    }
    @media (min-width: 992px) {
        /* md */
        height: 310px;
        overflow-y: hidden;
        .card-body {
            display: block;
        }
        h2 {
            font-size: 18px;
        }
    }
    @media (min-width: 1200px) {
        /* lg */
    }
}
