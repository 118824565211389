.inline-list {
    display: table;

    width: 100%;
    padding: 0;

    table-layout: fixed;
}

.inline-list li {
    display: table-cell;

    text-align: center;
    vertical-align: middle;
}

.inline-list li span {
    display: block;
}

.inline-list li span:first-child {
    font-weight: bold;
    font-size: 32px;
    font-family: "ITC Avant Garde Gothic Std", sans-serif;
}

@media (min-width: 48em) {
    .inline-list li span:first-child {
        font-size: 21px;
    }
}

@media (min-width: 62em) {
    .inline-list li span:first-child {
        font-size: 28px;
    }
}

@media (min-width: 75em) {
    .inline-list li span:first-child {
        font-size: 34px;
    }
}

.inline-list li span:last-child {
    font-size: 16px;
}

@media (min-width: 48em) {
    .inline-list li span:last-child {
        font-size: 12px;
    }
}

@media (min-width: 62em) {
    .inline-list li span:last-child {
        font-size: 14px;
    }
}

@media (min-width: 75em) {
    .inline-list li span:last-child {
        font-size: 16px;
    }
}

.inline-list--bordered li {
    padding: 0 25px;
}

.inline-list--spaced li {
    padding: 15px;
}

.list-bullet li:before,
.text-page ul li:before {
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    content: "";
}

/* stylelint-disable */
.list-bullet--diamonds li:before,
.text-page ul li:before {
    top: 5px;

    width: 15px;
    height: 19px;

    background-image: url("./images/list-diamond.svg");
}
/* stylelint-enable */

.list-bullet li,
.text-page ul li {
    position: relative;

    display: inline-block;
    width: 100%;
    margin: 10px 0;
    padding: 0 0 0 37px;
}

.inline-list--bordered li + li {
    border-left: 1px solid #51585c;
}

.list-bullet {
    padding: 0;

    list-style: none;
}
