.vx-axis-label,
.vx-legend-label {
    font-size: 10px;
}
.vx-legend {
    align-items: center;
    justify-content: center;
}
.legend_wrapper {
    text-align: center;
}
/* .progress {
  width: 100%;
}

.progress-tw .progress-bar {
  background-color: #fab150;
}
.progress-gp .progress-bar {
  background-color: #2ecefc;
}
.progress-gptw .progress-bar {
  background-color: #a6262b;
}
*/
