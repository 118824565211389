.react-modal {
    background: rgba(0, 0, 0, 0.45);

    .modal-dialog {
        width: 300px;
    }

    .modal-dialog:before {
        display: none;
    }

    .modal-body {
        padding: 0;

        .react-modal-close {
            position: absolute;
            top: 10px;
            right: 20px;
            z-index: 1;

            img {
                height: 20px !important;

                cursor: pointer;
            }
        }
    }
}

@media (min-width: 586px) {
    .react-modal {
        .modal-dialog {
            width: 450px;
        }
    }
}

@media (min-width: 768px) {
    .react-modal {
        .modal-dialog {
            width: 650px;
        }

        .modal-body {
            .react-modal-close {
                top: 20px;
                right: 30px;
            }
        }
    }
}
