.banner-side {
    display: inline-block;
    width: 185px;
    height: 150px;
}

.image-hire {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 100%;
    height: 100%;

    transform: translate(-50%, -50%);
}

.btn-hire {
    position: absolute;
    top: 100%;
    left: 50%;

    width: auto;
    margin-top: 2em;

    transform: translate(-50%, -50%);
}

@media (max-width: 92em) {
    .banner-side {
        width: 150px;
        height: 110px;
    }

    .btn-hire {
        font-size: 11px;
    }
}

@media (max-width: 75em) {
    .banner-side {
        display: none;
    }
}
