.socialmedia li a {
    display: block;
    width: 38px;
    height: 38px;

    background-color: #a6262b;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 3px;
}

.facebook {
    background-image: url("./images/facebook.svg");
}

.linkedin {
    background-image: url("./images/linkedin.svg");
}

.instagram {
    background-image: url("./images/instagram.svg");
    background-size: 45%;
}

footer {
    .footer-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 20px;
    }
}

@media (max-width: 48em) {
    .nav-inline {
        text-align: center;
    }

    .nav-inline li + li {
        padding: 0 0 0 15px;
    }
}

.squared {
    border-radius: 4px;
}

.badge {
    margin: -20px 5px 0 0;
    padding: 3px 6px;

    font-weight: normal;
    font-size: 12px;

    border-radius: 50%;
}

.badge--primary {
    background-color: #a6262b;
}