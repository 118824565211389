.lilXtraPadding{
    padding: 0 4rem;
}

.paddingTopBottom{
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.paddingTop{
    padding-top: 2rem;
}

.noPaddingBottom{
    padding-bottom: 0  !important;
}