.react-grid-item--cols {
    margin-bottom: 25px;

    background-color: #fff;
}

.game-stats {
    padding-bottom: 25px;

    color: #686d70;

    border-style: solid;
    border-width: 0;

    .stats-group {
        display: inline-block;
        min-width: 11.666666em;
        margin: 0px;
        padding-right: 1em;
        padding-left: 1em;

        background-color: #fff;
        border-color: #f3f3f3;
        border-left-width: 1px;
    }
    h3,
    h4 {
        height: 1em;

        color: #686d70;
        text-align: center;
    }
    h3 {
        color: #686d70;
        text-transform: uppercase;
    }
    h4 {
        color: #686d70;
        font-size: 400%;
    }
    table {
        width: 100%;
    }
    th {
        padding: 0 0.5em 0 0;

        font-weight: normal;
    }
    td {
        padding: 0;

        text-align: right;
    }
    .tooltipster-information-icon {
        float: right;
        width: 1em;
    }
    @media (max-width: 768px) {
        /* xs */
        float: left;

        font-size: 10px;
        text-align: left;
    }
    @media (min-width: 768px) {
        /* sm */
        font-size: 10px;
    }
    @media (min-width: 992px) {
        /* md */
        float: right;

        font-size: 10px;
        text-align: right;
    }
    @media (min-width: 1200px) {
        /* lg */
        font-size: 14px;
    }

    @media (min-width: 75em) {
        .inline-list li span:first-child {
            font-size: 34px;
        }
    }

    @media (min-width: 62em) {
        .inline-list li span:last-child {
            font-size: 14px;
        }
    }

    @media (min-width: 75em) {
        .inline-list li span:last-child {
            font-size: 16px;
        }
    }
}
